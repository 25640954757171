@import "https://fonts.googleapis.com/css?family=Lato:300,400,500,700";

@font-face {
    font-family: 'SFUIDisplay_Thin';
    src: url('/assets/fonts/sfuidisplay-thin.eot');
    src: url('/assets/fonts/sfuidisplay-thin.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/sfuidisplay-thin.woff2') format('woff2'),
         url('/assets/fonts/sfuidisplay-thin.woff') format('woff'),
         url('/assets/fonts/sfuidisplay-thin.ttf') format('truetype'),
         url('/assets/fonts/sfuidisplay-thin.svg#sf_ui_displaythin') format('svg');

}

@font-face {
    font-family: 'SFUIDisplay_Ultralight';
    src: url('/assets/fonts/sfuidisplay-ultralight.eot');
    src: url('/assets/fonts/sfuidisplay-ultralight.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/sfuidisplay-ultralight.woff2') format('woff2'),
         url('/assets/fonts/sfuidisplay-ultralight.woff') format('woff'),
         url('/assets/fonts/sfuidisplay-ultralight.ttf') format('truetype'),
         url('/assets/fonts/sfuidisplay-ultralight.svg#sf_ui_displayultralight') format('svg');

}

.fz-36-25 {
  font-size: 25px;
}

.fz-44-27 {
  font-size: 27px;
}

.fz-26-24 {
  font-size: 24px;
}

@media (max-width: 320px) {
  .fz-36-25 {
    font-size: calc(25px + (36 - 25) * ((100vw - 320px) / 640));
  }

  .fz-44-27 {
    font-size: calc(3.5937499999999996vw + 15.5px);
  }
}

@media (min-width: 960px) {
  .fz-36-25 {
    font-size: 36px;
    line-height: 44px;
  }

  .fz-44-27 {
    font-size: 44px;
    line-height: 54px;
  }

  .fz-26-24 {
    font-size: 26px;
    line-height: 32px;
  }
}

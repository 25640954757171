.phone-image {
  display: block;
  max-width: 100%;
  height: auto;
  @media (min-width: 1440px) {
    display: none;
  }
}

.main {
  background: linear-gradient(98.52deg, #035DC2 0%, #004A9D 100%);
  .container.first__container {
    padding-top: 50px;
  }

  .second__container .section__content {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .download {
    display: flex;
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
    }
  }

  .logo {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }

  .logo-text {
    margin-left: 16px;
  }

  .section__item_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
      align-items: center;
    }
  }

  .app-store {
    margin-right: 16px;
  }

  @media (min-width: 1025px) {
    .qr-code {
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        background-image: url(/assets/images/qr-hover.svg);
        top: -50%;
        left: 0;
        width: 120px;
        height: 120px;
        background-size: 120px 120px;
        visibility: hidden;
      }

      &:hover::after {
        visibility: visible;
      }
    }
  }

  @media (min-width: 1440px) {
    .container.first__container {
      padding-top: 8rem;
      padding-bottom: 16rem;
    }

    .container.second__container{
      padding-bottom: 7rem;
    }

    .iphone-blue-1,
    .iphone-blue-2 {
      position: relative;
    }

    .iphone-blue-1::after {
      content: '';
      top: -340px;
      position: absolute;
      width: 721px;
      height: 863px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(/assets/images/pic_iphone-1.png);
    }

    .iphone-blue-2::after {
      content: '';
      top: -200px;
      left: -120px;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      width: 718px;
      height: 828px;
      z-index: 1;
      background-size: cover;
      background-image: url(/assets/images/pic_iphone-2.png);
    }
  }
}


.section-3 {
  background-color: #fff;
  padding-top: 3rem;

  .section__item_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    padding-top: 5rem;
  }
}

.testimonials {
  padding-top: 2rem;
  padding-bottom: 2rem;

  .container {
    position: relative;
    z-index: 7;
  }

  .title {
    text-align: center;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 1149px) {
    .title {
      margin-bottom: 1rem;
    }
    background: linear-gradient(213.29deg, #FFFFFF 9.59%, #C4DFF3 100.34%);
  }
  @media (min-width: 1024px) {
    .title {
      margin-bottom: 2rem;
    }
    padding-top: 8rem;
    &::before {
      top: -100px;
      content: '';
      position: absolute;
      background-image: url(/assets/images/customers-bg-top.png);
      width: 100%;
      height: 308px;
      background-size: 100% 308px;
    }
    &::after {
      top: 20px;
      padding: 0;
      content: '';
      position: absolute;
      z-index: 5;
      background-image: url(/assets/images/customers-bg-bottom.png);
      width: 100%;
      height: 550px;
      background-size: 100% 650px;
    }
    .shark::after {
      top: 50px;
      right: 560px;
      content: '';
      z-index: 2;
      position: absolute;
      background-image: url(/assets/images/shark.png);
      width: 70px;
      height: 66px;
      background-size: 70px 66px;
    }
  }

  .testimonials__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 767px) and (max-width: 992px) {
      justify-content: space-between;
    }
    @media (min-width: 992px) {
      justify-content: space-between;
      align-items: flex-start;
    }

    .testimonials__card {
      padding: 24px;
      width: 90%;
      @media (min-width: 767px) and (max-width: 992px) {
        width: 47%;
        margin-bottom: 20px;
      }
      @media (min-width: 992px) {
        width: 24%;
      };
      margin-bottom: 20px;
      background-color: #ffffff;
      border: 1px solid #DAE6F2;
      box-shadow: 0 5px 4px rgba(32, 54, 70, 0.15);
      border-radius: 3px;

      .testimonials__title {
        font-weight: bold;
        color: #424242;
      }

      .testimonials__comment {
        color: #424242;
        font-size: 14px;
        line-height: 21px;
      }

      .testimonials__rating {
        display: block;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 15px 0;

        &::before {
          content: url(/assets/images/star.png);
          padding-right: 4px;
        }
      }
    }

  }
}

.section-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  .section__item_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.mentioned {
  background: #1565C0;
  position: relative;
  z-index: 1;

  .container {
    padding: 2rem 0;
    @media (min-width: 1024px) {
      padding: 0 0 24px 0;
      position: relative;
      top: 70px;
    }
  }

  .title {
    margin:  0 auto 0 0;
    @media (max-width: 1024px) {
      margin: 0 auto;
    }
  }

  @media (min-width: 1025px) {
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      z-index: -1;
      width: 200px;
      height: 360px;
      background-size: 200px 360px;
    }
    &::before {
      left: 0;
      background-image: url(/assets/images/pic_bg_ocean_left.png);
    }
    &::after {
      right: 0;
      background-image: url(/assets/images/pic_bg_ocean_right.png);
    }
  }

  .splide__track {
    padding-bottom: 16px;
  }

  .splide__arrows {
    display: flex;
    padding: 0 12px 34px;
  }

  .splide__arrow--prev:disabled {
    background-image: url(/assets/images/icon_prev--disabled.svg);
  }
  .splide__arrow--prev {
    margin-right: 16px;
    background-image: url(/assets/images/icon_prev.svg);
  }
  .splide__arrow--next {
    background-image: url(/assets/images/icon_next.svg);
  }

  .splide__arrow {
    position: static;
    top: initial;
    left: initial;
    opacity: 1;
    transform: none;
    background-color: transparent;
    display: block;
    width: 44px;
    height: 44px;
    background-position: center;
    background-size: contain;
  }
  .splide__pagination__page.is-active {
    background-color: #fff;
    border-color: #fff;
  }

  .splide__pagination__page {
    background-color: #b3d9f1;
    border-color: #b3d9f1;
  }

  .splide__pagination {
    position: absolute;
    z-index: 1;
    bottom: -1.5em;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }

  .splide__arrow {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .slider__card {
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #eee;
    font-size: 18px;
    justify-content: space-between;
    padding: 32px;
    box-shadow: 0 6px 16px rgba(32, 54, 70, 0.16);
    border-radius: 3px;
    height: 270px;

    .slider__card-text {
      text-align: center;
      color: #424242;
    }
  }

}

.section-7 {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: 1025px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .section__item_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section__item_right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    @media (min-width: 1025px) {
      padding-top: 0;

    }
  }
}

.info {
  padding-bottom: 2rem;

  .section__content {
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 768px) {
      justify-content: space-between;
    }

    .info__container {
      flex-basis: 100%;
      @media (min-width: 768px) {
        flex-basis: 48%;
      }
      text-align: justify;

      ul li {
        margin-top: 21px;
        display: flex;
      }

      ul li::before {
        content: url(/assets/images/icon_check.svg);
        margin-right: 16px;
        position: relative;
        top: -4px;
        align-self: flex-start;
      }

      .info__card {
        margin-bottom: 32px;
        position: relative;


        p {
          color: #7B7B7B;
        }

        .info__title {
          margin-bottom: 16px;
        }
      }

    }
  }
}

.get-app {
  background: #3177C7;

  .section__content {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .app-store {
      margin-top: 24px;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
      .app-store {
        margin-left: 24px;
        margin-top: 0;
      }
    }

  }
}

footer {
  background: #3A3A3A;
  color: #c1c1c1;

  .container .section__content {
    justify-content: center;
  }

}

.image {
  display: block;
  max-width: 100%;
  height: auto;
}

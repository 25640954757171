$text_color: #424242;
$text_color_white: #fff;

*,
*:after,
*:before {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

*:active,
*:focus,
*:hover {
	outline: none;
}

html {
	font-size: 18px;
}

html,
body {
	height: 100%;
}

body {
	font-family:'Lato', Arial, Helvetica, sans-serif;
	background-color: #fff;
	color: $text_color;
}

.section {
	position: relative;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.container {
	margin: 0 auto;
	max-width: 1170px;
	padding: 0 15px;
	width: 100%;
	.section__content {
		position: relative;
		display: flex;
		justify-content: space-between;
		@media (max-width: 767px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}
	.section__item {
		flex-basis: 50%;
		width: 100%;
	}
}
.disappear {
	@media (max-width: 767px){
		display: none;
	}
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
a {
	text-decoration: none;
}
p {
	line-height: 29px;
}
.flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.flex-column {
	flex-direction: column;
}
.section__item_right {
	justify-content: flex-end;
}
.section__item_left {
	justify-content: flex-start;
}
.white {
	color: $text_color_white;
}
.description {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.responsive{
	width: 100%;
	max-width:540px;
	height: auto;
}
.bordered {
	border: 1px solid #424242;
	border-radius: 10px;
}

.apple-button {
	transition: all 0.3s ease-in;

	&:hover {
		filter: drop-shadow(0px 5px 4px rgba(32, 54, 70, 0.15));
	}

	&:focus,&:active {
		filter: drop-shadow(0px 5px 4px rgba(32, 54, 70, 0.4));
	}
}

.apple-button--black {
	transition: all 0.3s ease-in;

	&:hover {
		filter: drop-shadow(0px 5px 8px rgba(32, 54, 70, 0.5));
	}

	&:focus,&:active {
		filter: drop-shadow(0px 5px 8px rgba(32, 54, 70, 0.3));
	}
}

.apple-button--white {
	transition: all 0.3s ease-in;

	&:hover {
		box-shadow: 0 6px 16px rgba(32, 54, 70, 0.2);
	}

	&:focus,&:active {
		box-shadow:0 6px 16px rgba(32, 54, 70, 0.5);
	}
}

.app-store{
	@media (max-width: 767px){
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.ewa-aic{
	align-items: center;
}
.ewa-jcc{
	justify-content: center;
}
.ewa-f{
	display: flex;
}

.title.fz-36-25  {
	@media (min-width: 1024px) {
		max-width: 540px;
	}
}

.description {
	@media (min-width: 1024px) {
		max-width: 445px;
	}
}
